.button {
	height: 40px;
	min-width: 72px;
	border-radius: 12px;
	padding: 0 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 12px;
	color: #fff;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.button[disabled] {
	opacity: 0.5;
	cursor: not-allowed;
}

.button[data-variant="primary"] {
	background-image: url("./primary.svg");
}

.button[data-variant="secondary"] {
	background-image: url("./secondary.svg");
}
