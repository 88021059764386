.shareButton {
	--size: 52px;
	width: var(--size);
	height: var(--size);
	min-width: var(--size);
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url("./share-bg.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 16px;
	box-shadow: 8px 8px 24px 0px #090d1466, -4px -4px 8px 0px #e0e0ff0a, 0px 1px
		1px 0px #090d1466;
}
