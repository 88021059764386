.backdrop {
	background: #000000b8;
}

.sheetContent {
	background-image: url("./sheet-bg.svg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: white;
	background-color: #737373;
	animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0);
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px 24px 24px;
	border-bottom: none;
}

.title {
	font-size: 1rem;
	font-weight: 600;
	text-align: center;
	flex-grow: 1;
}

.content {
	padding: 0 24px;
}
