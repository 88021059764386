@import "~stream-chat-react/dist/scss/v2/index.scss";

.str-chat {
	--str-chat__primary-color: #9c42f5;
	--str-chat__secondary-surface-color: #4a4b59;
	--str-chat__primary-surface-color: #4a4b59;
	--str-chat__secondary-background-color: #21242999;
	--str-chat__primary-surface-color-low-emphasis: #edf7f7;
	--str-chat__border-radius-circle: 6px;
	--str-chat__background-color: #21242999;
	--str-chat__text-color: #ffffff;
}

.str-chat__list {
	background-image: url("../../../assets/bubblesBackground.svg");
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
}
