.searchContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	background-color: #ffffff05;
	padding: 0 16px;
	border-radius: 16px;
	border: 1px solid #ffffff1f;
	min-height: 52px;
	height: 52px;
}

.searchContainer:focus-within {
	border-color: #ffffff3d;
}

.searchIcon {
	min-width: 20px;
}

.searchInput {
	flex: 1;
	height: 100%;
	background: transparent;
	color: #fff;
}

.searchInput::placeholder {
	color: #ffffff66;
}

.searchInput:focus {
	outline: none;
}

.searchInput:disabled {
	opacity: 0.3;
	cursor: not-allowed;
}
