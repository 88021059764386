.button {
	background: linear-gradient(
		135deg,
		#00ffaa 0%,
		#4579f5 53.01%,
		#9c42f5 99.83%
	);
	border-radius: 16px;
	height: 52px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	box-shadow:
		0px 8px 8px -4px #4579f51f, 0px 16px 24px 0px #4579f53d, 0px 2px 4px -1px #1b0a521f, 0px 0px 1px 0px #4579f53d;
	transition: all 200ms ease-in-out;
}

.button:active,
.button:hover {
	opacity: 0.9;
}

.button:active {
	transform: scale(0.98);
}

.button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
