.description {
	font-size: 14px;
	margin-bottom: 1.5rem;
}

.markAsReadButton {
	margin-bottom: 20px;
	align-self: flex-end;
}

.actions {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 1.5rem;
	gap: 8px;
}
