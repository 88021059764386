.actionButton {
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	width: fit-content;
	transition: transform 0.1s ease-in-out;
}

.actionButton:active {
	transform: scale(0.98);
}

.actionButton:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}
