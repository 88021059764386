.list {
	margin: 16px 0;
	max-height: 80vh;
	overflow-y: auto;
	gap: 8px;
}

.status {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 320px;
}

.notification {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #f5f7fa0f;
	background: linear-gradient(
		135deg,
		rgba(245, 247, 250, 0.12) 0%,
		rgba(245, 247, 250, 0.06) 52.14%,
		rgba(245, 247, 250, 0.0001) 100%
	);
	height: 64px;
	padding: 0 12px;
	border-radius: 16px;
}

.notificationContent {
	display: flex;
	flex-direction: column;
	gap: 4px;
	justify-content: center;
}

.title {
	font-size: 14px;
	font-weight: 600;
}

.body {
	font-size: 12px;
	color: #b3b3b3;
	max-width: 210px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.actions {
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;
}
