.button {
	color: #fff;
	position: relative;
}

.notificationCount {
	position: absolute;
	top: -4px;
	right: -4px;
	background: linear-gradient(
		135deg,
		#00ffaa 0%,
		#4579f5 53.01%,
		#9c42f5 99.83%
	);
	border-radius: 50%;
	min-width: 16px;
	height: 16px;
	padding: 0 4px;
	font-size: 10px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}
.button[aria-current="true"] {
	color: #06b6d4;
}
