.qrCodeContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("./qr-background.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	height: 320px;
	width: 100%;
	margin: -60px auto -40px;
}

.inviteCode {
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	margin: 0 auto 24px;
	background: #e0e0ff0a;
	padding: 8px 12px;
	border-radius: 12px;
}

.inviteLink {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #e0e0ff0a;
	padding: 12px 16px;
	border-radius: 12px;
	margin-bottom: 32px;
	font-size: 12px;
	font-weight: 500;
}

.inviteUrl {
	background: linear-gradient(
		135deg,
		#00ffaa 0%,
		#4579f5 53.01%,
		#9c42f5 99.83%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.copyButton {
	min-width: 16px;
}

.shareButton {
	margin-bottom: 32px;
}
