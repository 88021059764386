.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #f5f7fa0f;
	background: linear-gradient(
		135deg,
		rgba(245, 247, 250, 0.12) 0%,
		rgba(245, 247, 250, 0.06) 52.14%,
		rgba(245, 247, 250, 0.0001) 100%
	);
	height: 64px;
	padding: 0 12px;
	border-radius: 16px;
}

.content {
	display: flex;
	align-items: center;
	gap: 12px;
}

.avatar {
	--size: 40px;
	width: var(--size);
	height: var(--size);
	border-radius: 12px;
}

.name {
	font-weight: 600;
	font-size: 14px;
	line-height: 1.4;
}
