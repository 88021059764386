.searchContainer {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 12px;
}

.friendList {
	overflow-y: auto;
	gap: 8px;
	display: flex;
	flex-direction: column;
	padding-bottom: 24px;
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
}
